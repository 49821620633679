import styled, {css}  from "styled-components";
const MediumFont = 'Rubik-Medium';
const BoldFont = 'Rubik-Bold';
const RegularFont = 'Rubik-Regular';
const MainSurveyContainer = styled.div`
  width: 100%;
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media(max-width: 830px){
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const SurveyTitle = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px; 
  padding-top: 50px;
  > div: first-child{
    color: #005C87;
    font-family: ${MediumFont};
    font-size: 32px;
    font-weight:500;
    text-transform: capitalize;
  }
  /* > div: last-child{
    color: #67676A;
    font-family: ${MediumFont};
    font-size: 14px;
    opacity: 0.5;
  } */
`;
const SurveySideMenu = styled.div`
  width: 30%;
  padding: 0 10px 0 0;
  @media (max-width: 830px){
    width: 60%;
  }
  @media (max-width: 500px){
    width: 100%;
    padding: 10px;
  }
`;
const SurveyQuestionsContainer = styled.div`
  width: ${({width}) => width ? 'calc(70% - 40px)' : '1246px'};
  margin: ${({width}) => width ? '0 20px' : '0 auto'};
  @media (max-width: 830px){
    width: ${({width}) => width ? '60%' : '100%'};
    margin: 0 auto;
  }
  @media (max-width: 500px){
   width: 100%;
   margin: 0 auto;
   padding: 10px;
  }
`;
const CategoriesContainer = styled.button`
  width: 100%;
  padding: 20px;
  background-color: ${({completed}) => completed ? '#1ac6be' : '#fff'};
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  border: none;
  margin-bottom: 4px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  text-transform: capitalize;
  > div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div: first-child{
      text-align: left;
      width: 70%;
      > img{
        width: 40px;
      }
      > span{
        color: #282C37;
        font-family: ${MediumFont};
        font-size: 14px;
        text-transform: capitalize;
        @media(max-width: 340px){
          margin-left: 1px;
        }
      }
    }
    > div: last-child{
      width: 30%;
      font-family: ${RegularFont};
      color: #6D819C;
      font-size: 14px;
      text-transform: capitalize;
    }
  }
  ${({ disabled }) => disabled === 1 && css`
    opacity: 0.4;
    cursor: not-allowed !important;
  `}
  ${({ active }) => active === true && css`
    background-color: #E9F3FB;
    border: 2px solid #1E76AB;
  `}
`;
const RenderSurveyQuestions = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    float: left;
    margin: ${({width}) => width ? '0 0 30px 15px' : 'auto'};
    align-items: center;
    padding: 0 10px;
    @media (max-width: 830px){
     padding: ${({width}) => width ? '0px' : '0 10px'};
     margin:  ${({width}) => width ? '10px 0' : 'auto'};
    }
    @media (max-width: 664px){
     width: 100%;
     margin: 0px;
    }
`;
const RenderQuestionContainer = styled.div`
  padding: ${({width}) => width ? '45px' : '30px 0px'};
  width: 100%;
  border-radius: 6px;
  background-color: #fff;
  margin-bottom: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  @media(max-width: 670px){
    padding: 10px 20px;
  }
`;
const QuestionContainer = styled.div`
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  >div: first-child{
    font-size: 20px;
    opacity: 0.7;
    text-align:center;
    color:#78a9c0;
  }
  >div {
    color: #484856;
    font-family: ${MediumFont};
    font-size: 20px;
    text-align:center;
    color:#005C87
  }
  @media(max-width: 500px){
    min-height: 120px;
  }
`;

const OptionContainer = styled.div`
  border: 1px solid #669db7;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 15px;
  cursor: pointer;
  margin: 15px auto;
  width: 450px;
  min-height: 60px;
  .option{
    word-break: break-all;
  }
  > div: first-child{
    width: 25%;
    display: flex;
    justify-content: start;
  }
  > div: last-child{
    width: 50%;
    font-size: 18px;
    color: #11678f;
    font-family: ${RegularFont}
    padding-top: 3px;
    display:flex;
    justify-content: center;
  }
  ${({ isActive }) => isActive && css`
    background-color: ${({bgColor}) => bgColor ? bgColor : '#4798d9'};
    color: #fff;
    border: none;
    > div: first-child {
      >div{
        display: flex;
        justify-content:"center"
      }
    }
    > div: last-child {
      color: #fff;
      font-family: ${MediumFont}
    }
  `}
`;

const CheckMark = styled.span`
  margin-bottom: 3px;
  &::after {
    content: "";
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8px;
    height: 14px;
    border-style: solid;
    border-color: ${({bgColor}) => bgColor ? bgColor : '#4798d9'};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top-color: transparent;
    border-left-color: transparent;
  }
`;
const CheckMarkContainer = styled.div`
  display: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  align-items: center;
  justify-content: center;
`;

const YesNoWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 22px;
`;

const YesNoContainer = styled.div`
  width:150px;
  height:150px; 
  border:1px solid #669db7;
  border-radius:79px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#11678f;
  text-transform: capitalize;
  &:hover {
    cursor : pointer;
  } 
  ${({ isActive }) => isActive && css`
    background-color: ${({bgColor}) => bgColor ? bgColor : '#4798d9'};
    color: #fff;
    border: none;
  `}
`;

const McqWrapper = styled.div`
  display: ${({isTwoColumns}) => isTwoColumns ? 'grid' : 'flex' };
  flex-direction: column;
  grid-template-columns: repeat(2,auto);
  grid-template-rows: repeat(5,auto);
  grid-auto-flow: column;
  padding:${({isTwoColumns}) => isTwoColumns ? '0px 115px' : '0px' };
`

const McqContainer = styled.div `
  border: 1px solid #669db7;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: start;
  flex: 1;
  padding: 15px;
  cursor: pointer;
  margin: 15px auto;
  width: 450px;
  min-height: 62px;
  flex-basis: ${(props) => props.isTwoColumns && `50%`};
  ${'' /* white-space: nowrap; */}
  > div: first-child{
    width: 30px;
    display: flex;
    justify-content: center;
  }
  > div: last-child{
    width: 90%;
    font-size: 18px;
    color: #11678f;
    font-family: ${RegularFont}
    padding-top: 3px;
    display:flex;
    justify-content: center;
  }
  ${({ isActive }) => isActive && css`
    background-color: ${({bgColor}) => bgColor ? bgColor : '#4798d9'};
    color: #fff;
    border: none;
    > div: first-child {
      width:30px;
      >div{
        display: flex;
        
      }
    }
    > div: last-child {
      color: #fff;
      font-family: ${MediumFont};
      display:flex;
      width:90%;
      justify-content:center;
      ${'' /* margin-left:15px; */}
    }
  `}
`


const ProgressBarContainer = styled.div`
  Width: 100%;
  height: 4px;
  background-color: rgba(196, 100, 172, 0.2);
  border-radius: 2px 2px 0px 0px;
`;
const FillProgressBar = styled.div`
  width: ${({fillWidth})=> fillWidth && fillWidth}
  background-color: #C464AC;
  height: 4px;
  border-radius: 2px;
`;
const ButtonContainer = styled.div`
  width: 100%;
  /* float: left; */
  display: flex;
  align-items: center;
  justify-content: ${({justify}) => justify ? 'flex-end' : 'space-between'};
  margin: ${({margin}) => margin ? '0 0 30px 15px' : '15px 0'};
  @media (max-width: 664px){
    margin: 10px 0px;
  }
`;
const SubmitSurvey = styled.button`
  margin-top:10px ;
  width: ${({style}) => style && style.width ? style.width : '200px'};
  height: 58px;
  background-color: ${({style}) => style && style.backgroundColor ? style.backgroundColor : '#005c87'};
  font-size: 18px;
  color: ${({style}) => style && style.color ? style.color : '#FFFFFF'};
  border-radius: 3px;
  font-weight: 500;
  border: ${({style}) => style && style.border ? style.border : 'none'};
  float:${({style}) => style && style.float ? 'right' : 'none'} ;
  outline: none;
  font-family: ${BoldFont};
  ${({ disabled }) => disabled === true && css`
   opacity: 0.5;
   cursor: not-allowed !important;
 `}
`;
const TextAreaContainer = styled.div`
  width: 1095px;
  margin: auto;
`;

const ToggleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  color: #649bb3 ;
`

const StepWrapper = styled.div`
  display: flex;
  justify-content: center;
  >div:first-child {
    border-radius: 10px 0px 0px 10px;
  }
  >div:last-child {
    border-radius: 0px 10px 10px 0px ;
  }
`

const StepIndicator = styled.div`
width: 25px;
height: 15px;
background-color: ${({isCompleted, isActive}) => (isCompleted || isActive) ? '#649bb3' : '#dfe6e1'};
border: 1px solid #ccc;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
margin-right: 3px;
`
const ScaleSlider = styled.input`
  -webkit-appearance: none;
  width: 825px;
  height: 35px;
  border-radius: 24px;
  background: linear-gradient(
    to right,
    #d3d3d3 0% 0%,
    ${(props) => (props.value <= 5 ? '#f4aaa9' : (props.value > 5 && props.value <=7) ? '#f1c977' : (props.value > 7 && props.value <= 10) ? '#76ab78' : '#e0e0e0' )} 0% ${(props) => (props.value <= 5 ? props.value * 10 : (props.value) * 10)}%,
    #f1c977 0% ${(props) => (props.value > 5 ? props.value * 10 : (props.value) * 10)}%,
    #76ab78 0% ${(props) => (props.value > 7 ? props.value * 10 : (props.value) * 10)}%,
    #d3d3d3 ${(props) => (props.value < 10 ? props.value * 10 : 100)}%
  );
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 50px;
    height: 50px;
    border: white solid 4px;
    border-radius: 32px;
    background: ${(props) =>
    props.value === 0
      ? '#4caf50 url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'35\' height=\'35\' viewBox=\'0 0 35 35\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'><rect width=\'35\' height=\'35\' rx=\'17.5\' fill=\'#76AB78\'/><rect x=\'15\' y=\'9\' width=\'4\' height=\'17\' rx=\'2\' fill=\'white\'/><rect x=\'21\' y=\'12\' width=\'4\' height=\'11\' rx=\'2\' fill=\'white\'/><rect x=\'9\' y=\'12\' width=\'4\' height=\'11\' rx=\'2\' fill=\'white\'/></svg>") center center no-repeat' :
      props.value <= 5 ? '#f4aaa9 url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' viewBox=\'0 0 24 24\' fill=\'none\'><path d=\'M12 24C5.37257 24 0 18.6274 0 12C0 5.37257 5.37257 0 12 0C18.6274 0 24 5.37257 24 12C24 18.6274 18.6274 24 12 24ZM10.8306 16.6037C11.171 16.6035 11.5107 16.4731 11.7703 16.2135L18.3746 9.60919C18.8943 9.08955 18.8929 8.24664 18.3723 7.72584C17.848 7.20152 17.0084 7.20418 16.4889 7.72366L10.8296 13.3829L8.9446 11.4979C8.4253 10.9786 7.57921 10.9766 7.05837 11.4972C6.53406 12.0216 6.53774 12.8622 7.0582 13.3828L9.88712 16.2115C10.1488 16.4732 10.4893 16.6034 10.8296 16.6033L10.8306 16.6037Z\' fill=\'white\'/></svg>") center center no-repeat' :
        (props.value > 5 && props.value <=7) ? '#f1c977 url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' viewBox=\'0 0 24 24\' fill=\'none\'><path d=\'M12 24C5.37257 24 0 18.6274 0 12C0 5.37257 5.37257 0 12 0C18.6274 0 24 5.37257 24 12C24 18.6274 18.6274 24 12 24ZM10.8306 16.6037C11.171 16.6035 11.5107 16.4731 11.7703 16.2135L18.3746 9.60919C18.8943 9.08955 18.8929 8.24664 18.3723 7.72584C17.848 7.20152 17.0084 7.20418 16.4889 7.72366L10.8296 13.3829L8.9446 11.4979C8.4253 10.9786 7.57921 10.9766 7.05837 11.4972C6.53406 12.0216 6.53774 12.8622 7.0582 13.3828L9.88712 16.2115C10.1488 16.4732 10.4893 16.6034 10.8296 16.6033L10.8306 16.6037Z\' fill=\'white\'/></svg>") center center no-repeat' :
          (props.value > 7 && props.value <= 10) ? '#76ab78 url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' viewBox=\'0 0 24 24\' fill=\'none\'><path d=\'M12 24C5.37257 24 0 18.6274 0 12C0 5.37257 5.37257 0 12 0C18.6274 0 24 5.37257 24 12C24 18.6274 18.6274 24 12 24ZM10.8306 16.6037C11.171 16.6035 11.5107 16.4731 11.7703 16.2135L18.3746 9.60919C18.8943 9.08955 18.8929 8.24664 18.3723 7.72584C17.848 7.20152 17.0084 7.20418 16.4889 7.72366L10.8296 13.3829L8.9446 11.4979C8.4253 10.9786 7.57921 10.9766 7.05837 11.4972C6.53406 12.0216 6.53774 12.8622 7.0582 13.3828L9.88712 16.2115C10.1488 16.4732 10.4893 16.6034 10.8296 16.6033L10.8306 16.6037Z\' fill=\'white\'/></svg>") center center no-repeat' :
            '#76ab78'
};
    cursor: pointer;
  }
`;
const ScaleSliderContainer = styled.div`
  display: flex;
  width: 825px;
  height: 220px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: relative;
`;
const ScaleLabels = styled.div`
  display: flex;
  justify-content: space-between;
  width: 760px;
  color:#005c87;
`;

const ScaleRuler = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TextAboveThumb = styled.div`
  width: 825px;
  position: absolute;
  left: ${(props) => {
    if (props.value <= 5) {
      return `calc(${(props.value / 10) * 100}% - ${props.value * 10}px)`;
    } else if (props.value <= 7) {
      return `calc(${(props.value / 10) * 100}% - 50px)`;
    } else if (props.value <= 9) {
      return `calc(${(props.value / 10) * 100}% - 60px)`;
    } else {
      return `calc(${(props.value / 10) * 100}% - 65px)`;
    }
  }};
  font-size: 14px;
  top:0px;
  text-align: center;
  >p{
    width:90px;
    color: ${(props) => (props.value <= 5 ? '#f4aaa9' : props.value <= 7 ? '#f1c977' : props.value <= 10 ? '#76ab78' : '')};
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 39px;
  height: 20px;
  background-color: ${(props) => (props.isToggle ? '#76ab78' : '#b3cfdb')};
  border: 1px solid #ccc;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
`;

const Thumb = styled.div`
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  transform: translateX(${(props) => (props.isToggle ? '19px' : '0')});
  transition: transform 0.3s ease-in-out;
`;

const PopUpContainer = styled.div`
  width: 475px;
  height: 465px;
  border-radius: 6px;
  top:calc(50% - (420px / 2)) ;
  left: calc(50% - (475px / 2));
  z-index: 1;
  position: fixed;
  background-color: white;
`
const TitleDiv = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 6px 6px 0px 0px;
  background-color: #005C87;
  color: white;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ContentDiv = styled.div`
  width: 350px;
  height: 60px;
  margin: auto;
  text-align: center;
  color: #005C87;
  margin-top: 15px;
  line-height: 15px;
  > p{
    font-size: 16px;
    font-weight: 500;
  }
`
const LogoDiv = styled.div`
  width: 155px;
  height: 182px;
  margin: auto;
  margin-top: 25px;
`
const ThankButton = styled.div`
  width: 323px;
  height: 50px;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 26px;
  border-radius: 4px;
  background-color:#005C87;
  color: white;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: #005C871A 0px 10px 36px 0px, #005C871A 0px 0px 0px 1px;
  cursor: pointer;
`

export {PopUpContainer,TitleDiv,ContentDiv,LogoDiv,ThankButton,ToggleContainer, Thumb, ScaleSlider,ScaleRuler,TextAboveThumb,ScaleSliderContainer,ScaleLabels, StepIndicator,StepWrapper,MainSurveyContainer, SurveySideMenu, SurveyTitle,YesNoWrapper,ToggleWrapper, YesNoContainer,McqWrapper, McqContainer, SurveyQuestionsContainer, CategoriesContainer, QuestionContainer, RenderQuestionContainer, OptionContainer, CheckMark, CheckMarkContainer, ProgressBarContainer, FillProgressBar, SubmitSurvey, RenderSurveyQuestions, ButtonContainer, TextAreaContainer};
